import { Css_Text_20_30_To_16_24 } from '@dreamstack/feature-components'
import type { BlockTextCloudFragment } from '@dreamstack/investors-graphql'
import indexOf from 'lodash/indexOf'
import map from 'lodash/map'
import type { FunctionComponent} from 'react';
import { useMemo } from 'react'
import tw, { styled } from 'twin.macro'

const BlockTextTile = styled.div<{
  index: number
}>(({ index }) => {
  const isSecond = index === 1
  const isThird = index === 2

  return [
    tw`bg-accentroGray-full text-accentroWhite-full font-semibold max-w-xl justify-center
    px-px-24 pt-px-24 pb-px-64 m-px-4 
    md:(pt-px-48 px-px-40 max-width[387px] pb-px-96)`,
    Css_Text_20_30_To_16_24,
    isSecond && tw`md:(max-width[347px]) ml:(max-width[387px])`,
    isThird && tw`md:(max-width[350px])`,
  ]
})

const BlockWrapper = styled.div<{
  count: number
}>(({ count }) => [
  tw`flex flex-col justify-center`,
  count <= 3 && tw`md:(flex-row)`,
  count > 3 && tw`md:(grid grid-cols-2 items-center) ml:(flex flex-col)`,
])

const GroupOne = styled.div<{
  count: number
}>(({ count }) => [
  tw`flex flex-col`,
  count <= 3 && tw`md:(flex-col items-end)`,
  count > 3 && tw`md:(items-end) ml:(flex-row justify-center )`,
])

const GroupTwo = styled.div<{
  count: number
}>(({ count }) => [
  tw`flex flex-col`,
  count <= 3 && tw`md:(flex-col items-start justify-center) ml:(items-end)`,
  count > 3 && tw`ml:(flex-row items-start)`,
])

export const BlockTextCloud: FunctionComponent<React.PropsWithChildren<{
  block: BlockTextCloudFragment
}>> = ({ block }) => {
  const { groupOne, groupTwo, textFields } = useMemo(() => {
    const textFieldsRaw = [
      block.fieldName1,
      block.fieldName2,
      block.fieldName3,
      block.fieldName4,
      block.fieldName5,
    ]
    const textFields = textFieldsRaw.filter((t) => !!t)
    const groupOne: (string | null | undefined)[] = []
    const groupTwo: (string | null | undefined)[] = []
    textFields.forEach((t, i) =>
      i % 2 === 1 ? groupTwo.push(t) : groupOne.push(t)
    )
    return { groupOne, groupTwo, textFields }
  }, [
    block.fieldName1,
    block.fieldName2,
    block.fieldName3,
    block.fieldName4,
    block.fieldName5,
  ])

  const count = textFields.length

  return (
    textFields && (
      <div tw="flex justify-center">
        <BlockWrapper count={count}>
          <GroupOne count={count}>
            {map(groupOne, (textField, i) => (
              <BlockTextTile key={i} index={indexOf(textFields, textField)}>
                <div>{textField}</div>
              </BlockTextTile>
            ))}
          </GroupOne>
          <GroupTwo count={count}>
            {map(groupTwo, (textField, i) => (
              <BlockTextTile key={i} index={indexOf(textFields, textField)}>
                <div>{textField}</div>
              </BlockTextTile>
            ))}
          </GroupTwo>
        </BlockWrapper>
      </div>
    )
  )
}
